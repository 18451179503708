.ems-contribute-page-header {
  max-width: 400px;
  margin: 50px auto;
}

.ems-contribute-result {
  display: flex;
  max-width: 400px;
  margin: 100px auto;
}

.ems-contribute-loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.ems-contribute-success, .ems-contribute-error {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ems-contribute-error {
  color: #b60000;
  font-weight: bold;
}

.ems-contribute-back {
  align-self: normal;
}