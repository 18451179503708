.ems-pagination-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.ems-pagination-list {
  display: flex;
  align-items: center;
  flex-direction:row;
  flex: 1 1
}

.ems-pagination-list-pages,
.ems-pagination-list-buttons {
  display: flex;
  align-items: center;
  flex-direction:row;
}

.ems-pagination-list-button + .ems-pagination-list-button {
  margin-left:2px;
}

.ems-pagination-list-item-active {
  font-weight: 600;
  color: inherit;
  font-size: 1.1rem;
}

.ems-pagination-select,pagination-select:focus {
  padding:2px;
  border:none;
  outline:none;
}