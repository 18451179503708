.ems-contribute-uploader {
  margin: 0 auto;
  max-width: 400px;
}

.ems-contribute-uploader-dropzone {
  width: 100%;
  height: 300px;
  border: 10px dashed #d5d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ems-contribute-uploader-files {
  padding: 20px;
}

.ems-contribute-uploader-files-size {
  font-size: 0.5rem;
  color: #595959;
  display: block;
}

.ems-contribute-uploader-submit {
  width: 100%;
}

.ems-contribute-uploader-files-title {
  font-weight: bold;
  margin-bottom: 10px;
  display:flex;
}

.ems-contribute-uploader-files-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  flex-direction: column;
}

.ems-contribute-uploader-files-list-item {
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0;
}

.ems-contribute-uploader-files-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ems-contribute-uploader-files-remove-button {
  line-height: 0;
  padding: 0.3rem;
  font-size: 0.7rem;
  height: 2rem;
  margin-left: auto;
}