.ems-contribute-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 30px auto 0 auto;
}

.ems-contribute-form-field {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.ems-contribute-form-field-label {
  width: 40%;
  margin-right: 10px;
}

.ems-contribute-form-field-label-required {
  display: block;
  color: #808080;
  font-size: 0.5rem;
}


.ems-contribute-form-field-input {
  flex: 1;
}