.ems-asset-list-collection-item {
  display: flex;
  width: 100%;
}
.ems-asset-list-collection-item-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  border:1px solid rgba(0,0,0,0.1);
}

.ems-asset-list-collection-item-title {
  font-weight:600;
}
.ems-asset-list-collection-item-count:before {
  content:" - "
}

.ems-asset-list-collection-list-item-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ems-asset-list-collection-list-item-count:before {
  content: '(';
}

.ems-asset-list-collection-list-item-count:after {
  content: ')';
}

.ems-asset-list-collection-carousel {
  width: 100%;
}

.ems-asset-list-collection-carousel-wrapper{
  display:block;
  vertical-align:middle;
  position:relative;
}
.ems-asset-list-collection-carousel-wrapper > .ems-button {
  width:24px;
  height:24px;
  padding:4px;
  border-radius:50%;
  background-color:rgba(0,0,0,0.1);
  position:absolute;
  top:50%;
}
.ems-asset-list-collection-carousel-back-button {
  right:100%;
}
.ems-asset-list-collection-carousel-next-button {
  left:100%;
}

.ems-asset-list-collection-carousel-wrapper > .ems-carousel__slider--horizontal {
  display:inline-block;
  padding:0px;
  margin:0px;
  width:calc(100% - 32px);
}

.ems-asset-list-collection-carousel-container-next-button {
  position: absolute;
  top: 30%;
  right: -20px;
  z-index: 1000;
}

.ems-asset-list-collection-carousel-container-back-button {
  position: absolute;
  top: 30%;
  left: -20px;
  z-index: 1000;
}

.ems-asset-list-collection-carousel-click-more {
  align-self: flex-end;
  margin-top: 30px;
}

.ems-asset-list-collection-item-list-message {
  float:right;
}