.ems-pdf-preview-container {
  cursor: default;
  padding: 0.5rem;
}

.ems-pdf-preview-image-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}


.ems-pdf-preview-image-pagination-input {
  border: 0;
  display: inline-block;
  width: 50px;
  text-align: center;
}

.ems-pdf-preview-image-pagination-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}