.ems-articles-category-list {
  display: flex;
  flex-wrap: wrap;
}

.ems-articles-category-list .ems-articles-category-list-item {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  flex-direction: column;
  height: 170px;
  font-size: 16px;
}

.ems-articles-category-list-item:hover .ems-thumbnail-inner {
  transform: scale(1.1);
}

.ems-articles-category-list-item.active, .ems-articles-category-list-item:hover {
  border-bottom: 5px solid #313131;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  height: 175px;
}

.ems-articles-category-list-item:hover::after, .ems-articles-category-list-item.active::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #313131;
  bottom: -10px;
}

.ems-articles-category-list-item:hover::after {
  border-top-color: #ccc;
}

.ems-articles-category-list-item:hover {
  border-color: #ccc;
}

.ems-articles-category-list-item-thumbnail {
  height: 130px;
}

.ems-articles-category-list-item-thumbnail .ems-thumbnail-inner {
  transition: transform 0.125s linear, -webkit-transform 0.125s linear;
  background-size: cover;
}

.ems-articles-category-list-hidden {
  height: 0;
  overflow: hidden;
}