.ems-header {
  display: flex;
  position:relative;
}
.ems-header a, .ems-header a:hover {
  color: inherit;
  text-decoration: none;
  align-items:center;
  display:flex;
}
.ems-header--vertical {
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ems-header--horizontal {
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y:auto;
}
.ems-header-logo {
  height: 100%;
  width:auto;
  font-size: 1.5rem;
  white-space:nowrap;
}
.ems-header-logo:hover {
  color:#fff
}
.ems-header--vertical .ems-header-site-logo {
  border-right:1px solid rgba(255,255,255,0.25);
}
.ems-header--horizontal .ems-header-logo {
  flex-direction: column;
  height: auto;
}


.ems-header-nav-wrapper {
  display: flex;
  align-items: flex-start;
}
.ems-header--vertical .ems-header-nav-wrapper {
  align-self:stretch;
  flex-direction:row-reverse;
}
.ems-header--horizontal .ems-header-nav-wrapper {
  flex-direction:column-reverse;
}

.ems-header-nav {
  display: flex;
  align-items: center;
}
.ems-header-nav--vertical {
  align-self:stretch;
  justify-content: space-between;
}
.ems-header-nav--horizontal {
  flex-direction: column;
  align-items:flex-start;
}
.ems-header-nav-item {
  align-self:stretch;
}
.ems-header-nav-item-name{
}
.ems-header-nav-item-value{
  position:relative;
  top:-4px;
  margin-left:8px;
  padding:0px 4px;
  min-width:18px;
  border:1px solid rgba(255,255,255,0.25);
  border-radius:12px;
  text-align:center;
  line-height:16px;
  font-size:10px;
}

.ems-heading-title {
  font-weight:400;
  opacity: 0.5;
}
.ems-header--horizontal .ems-header-nav-mobile,
.ems-header--vertical .ems-header-nav-mobile {
  align-items: center;
  align-self:stretch;
  display:none;
}

.ems-header-nav-mobile .ems-header-nav-item{
  display: flex;
  align-items: center;
  align-self:stretch;
  cursor:pointer;
}

.ems-header--horizontal + .ems-main {
  height:100vh;
  overflow-y:auto;
}


@media only screen and (max-width:1200px) {
  .ems-header--vertical .ems-header-nav-mobile {
    display:flex;
  }
  .ems-header--vertical .ems-header-nav-wrapper {
    position:absolute;
    opacity:0;
    top:64px;
    right:-200px;
    width:200px;
    flex-direction:column-reverse;
  }
    .ems-header--vertical .ems-header-nav-wrapper .ems-header-nav{
      flex-direction:column;
    }

  .ems-header--vertical.ems-header-nav-open .ems-header-nav-wrapper  {
    right:0px;
    opacity:1;
  }
  .ems-header--vertical .ems-header-nav-wrapper .ems-header-nav-item {
    line-height:1em;
  }
}

