.ems-folder-list-item {
  display: flex;
  flex-direction: column;
}
.ems-folder-list-item .ems-item-inner {
  position:relative;
}
.ems-folder-list-item-title {
  font-weight:600;
}


.ems-folder-list-item-button {
  cursor: pointer;
  flex-grow:unset;
  justify-self:flex-end;
}

.ems-button.folder-list-item-button {
  width:20px;
  height:20px;
  align-self:flex-end;
  border:1px solid rgba(0,0,0,0.1);
}
