.ems-asset-results-heading-wrapper {
  display:flex;
  flex:1 1;
  align-items:center;
}

.ems-basket-clear {
  margin-left: 2px;
}

.ems-basket-tabs {
  display: flex;
  align-items:center;
  justify-content: flex-start;
}

.ems-basket-tabs-item {
  text-decoration: none;
  opacity:0.8;
  color:inherit;
}

.ems-basket-tabs-item .ems-pill-value:before {
  content:" - ";
}
