.ems-share-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: auto;
}
.ems-shares-heading {
  flex:unset;
}
.ems-shares-list-hidden {
  max-height: 0;
  overflow: hidden;
}
