.ems-confirm-box {
  position: absolute;
  z-index: 9000;
  top: 50%;
  left: 50%;
  min-width: 80vmin;
  min-height: 80vmin;
  background-color: #FFFFFF;
  transform: translateY(-50%) translateX(-50%);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid #dcdcdc;
}

.ems-confirm-box-content {
  max-height: calc(80vmin - 3rem - 55px);
  margin-bottom: 1rem;
  flex: 1;
  overflow: scroll;
}

.ems-confirm-box-buttons {
  display: flex;
  justify-content: flex-end;
  height: 55px;
}

.ems-confirm-box-buttons > .ems-pill,
.ems-confirm-box-buttons > .ems-pill:hover {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}