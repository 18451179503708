.ems-item-view-share-content-play-original .ems-item-view-content {
  flex-direction: column;
}

.ems-item-view-share-content-play-original .ems-item-view-content-image {
  width: 100%;
}

.ems-item-view-share-content-play-original .ems-item-view-content-details {
  width: 100%;
  margin-top: 30px;
}

.ems-item-view-share-content-play-original .ems-item-view-content-details-buttons {
  margin-bottom: 5px;
}


/* demo only needs to be removed */

.ems-item-view-content {
  position: relative;
}

.ems-item-view-watermark {
  position: absolute;
  top: 40%; left: 25%;
  padding: 0;
  margin: 0;
  z-index: 50;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.7);
  transform:  translate(-50%, -25%) rotate(-45deg);
}