.ems-player-container {
  position: relative;
  cursor: auto;
  min-height: 300px;
}

.ems-player-buffering {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}

.ems-player-container:hover .ems-player-controls {
  opacity: 1;
}

.ems-player-controls {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  height: 40px;
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}

.ems-player-controls-time {
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 20px;
  padding: 7px;
  min-width: 90px;
  margin: 10px 0 10px 10px;
}

.ems-player-controls-progress {
  display: flex;
  flex: 1;
  position: relative;
  margin: 10px;
}

.ems-player-controls-progress-bar, .ems-player-controls-loaded-bar {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 6px;
  width: 0;
}

.ems-player-controls-progress-bar {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.ems-player-controls-loaded-bar {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 2;
  transition: width .3s linear;
}

.ems-player-controls-progress-range {
  display: flex;
  flex: 1;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  z-index: 3;
  cursor: pointer;
}

.ems-player-controls-progress-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

.ems-player-controls-progress-range::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

.ems-player-controls-progress-range::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

.ems-player-controls-button {
  display: flex;
  min-width: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-items: center;
  border-radius: 7px;
  padding: 14px;
  margin: 0;
}
