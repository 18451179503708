
.ems-articles-category-heading {
  flex:unset;
}
.ems-articles-category-toggle {
  display: flex;
  align-items: center;
}

.ems-article-list-item-inner {
  display:flex;
  flex-direction:row;
}
.ems-article-list-item-thumbnail {
  width:25%;
}
.ems-article-list-item-details {
  width:75%;
  color:inherit;
}
.ems-article-list-item-title {
  font-weight:400;
  font-size:120%;
}


.ems-article-list--column .ems-article-list-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.ems-article-list--column .ems-article-list-item {
  display: flex;
}

.ems-article-list--column .ems-article-list-item-inner {
  flex-direction: column;
}

.ems-article-list--column .ems-article-list-item-thumbnail {
  width: auto;
  height: 250px;
}

.ems-article-list--column .ems-article-list-item-thumbnail-inner {
  height: 100%;
  padding-top: 0;
}

.ems-article-list--divide-1 .ems-article-list-item {
  width: 100%
}

.ems-article-list--divide-2 .ems-article-list-item {
  width: 50%
}

.ems-article-list--divide-3 .ems-article-list-item {
  width: calc(100%/3);
}

.ems-article-list--divide-4 .ems-article-list-item {
  width: 25%;
}